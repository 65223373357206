import type { BackendTypes } from '@tf/api';

export type FormStatuses = {
	structure?: BackendTypes.FieldValidationStatus;
	review?: BackendTypes.ReviewStatus;
};

// Compute form statuses based on segment review containers
export const getFormStatuses = ({
	containers,
	segmentIdentities,
}: {
	containers: BackendTypes.SegmentReviewContainer[];
	segmentIdentities: BackendTypes.SegmentIdentity[];
}) => {
	const nextStatuses: FormStatuses = {};

	/**
	 * Find segment review container for each segment identity
	 */
	const statuses: FormStatuses[] = [];
	for (const s of segmentIdentities) {
		const segmentReviewContainer = containers.find(
			({ identity: { graphId, segmentKind } }) => graphId === s.graphId && segmentKind === s.segmentKind
		);
		if (!segmentReviewContainer) continue;
		statuses.push({
			structure: segmentReviewContainer.status,
			review: segmentReviewContainer.reviewStatus,
		});
	}

	/**
	 * If no segment review containers found, return empty state
	 */
	if (statuses.length === 0) {
		return nextStatuses;
	}

	/**
	 * Compute structure status
	 */
	if (statuses.every(({ structure }) => structure === 'OK')) {
		nextStatuses.structure = 'OK';
	} else if (statuses.some(({ structure }) => structure === 'VALIDATION_ERRORS')) {
		nextStatuses.structure = 'VALIDATION_ERRORS';
	} else {
		nextStatuses.structure = 'PENDING_FILL';
	}

	/**
	 * Compute review status
	 */
	if (statuses.every(({ review }) => review === 'ACCEPTED')) {
		nextStatuses.review = 'ACCEPTED';
	} else if (statuses.some(({ review }) => review === 'REJECTED')) {
		nextStatuses.review = 'REJECTED';
	}

	return nextStatuses;
};
