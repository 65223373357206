import React from 'react';

import type { FC } from 'react';
import type { FieldError } from 'react-hook-form';

import { Icon, Tooltip } from '@tf/ui';

interface TooltipIconProps {
	style?: React.CSSProperties;
	tooltip?: string;
	error?: FieldError;
}

export const TooltipIcon: FC<TooltipIconProps> = ({ tooltip, error, style }) => {
	if (error) {
		return <InvalidIcon style={style} message={error.message} />;
	}

	if (tooltip) {
		return (
			<Tooltip multiline w={200} label={tooltip} position="top-end" withArrow>
				<Icon.IconInfoCircle
					size={18}
					style={{ minWidth: '18px', display: 'block', opacity: 0.5, cursor: 'help', ...style }}
				/>
			</Tooltip>
		);
	}

	return null;
};

const AlertIcon = React.forwardRef<HTMLDivElement>((props, ref) => (
	<div ref={ref} {...props} style={{ display: 'flex', alignItems: 'center' }}>
		<Icon.IconAlertCircle size={18} color="#f95352" />
	</div>
));

const InvalidIcon = ({ message, style }: { message?: string; style?: React.CSSProperties }) => {
	return (
		<Tooltip style={style} position="top-end" label={message} withArrow>
			<AlertIcon />
		</Tooltip>
	);
};
