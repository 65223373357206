import { useMemo } from 'react';

import { useClerk, useSignIn } from '@clerk/clerk-react';

export const useSignInWithTicket = () => {
	const { signOut } = useClerk();
	const { signIn, setActive } = useSignIn();

	return useMemo(() => {
		if (!signIn) {
			return null;
		}

		return async (ticket: string) => {
			// Clerk automatically redirects after signing out even if redirectUrl isn't provided.
			// We don't need this redirect here, because we need to sign in with provided token right after sign out.
			// Passing callback disables redirect behaviour.
			await signOut(async () => {});

			const signInResource = await signIn.create({ strategy: 'ticket', ticket });
			await setActive({ session: signInResource.createdSessionId });
		};
	}, [signOut, signIn, setActive]);
};
