export enum FIELD_MODIFIERS {
	TEXTAREA = 'textarea',
	PERCENTAGE = 'percentage',
	DATE_PERIOD = 'date_period',
	DATE = 'date',
	DATE_MONTH_YEAR = 'date_month_year',
	ADDRESS = 'address',
	PHONE = 'phone',
	BIRTH_PLACE = 'birth_place',
	MONEY = 'money',
	MONEY_SELECT = 'money_select',
	MONEY_LABEL = 'money_label',
	DOCUMENTS_CHECK = 'documents_check',
	BACKGROUND_CHECK = 'background_check',
	LIVENESS_CHECK = 'liveness_check',
}

export const CURRENCY_SYMBOLS: Record<string, string> = {
	USD: '$',
	EUR: '€',
	GBP: '£',
	CHF: '₣',
};
